
















import { BFormInput, BFormGroup, BCol, BRow } from "bootstrap-vue";
import { format, parseISO } from "date-fns";
import debounce from "debounce";
import { Component, Vue, Watch } from "vue-property-decorator";

import { inflateEntity } from "@/api/_request";
import BlogPostAppModel from "@/api/blog_post.app.model";

import PostCard from "@/layouts/components/blog/user/PostCard.vue";

import { BlogPost } from "@/interfaces/blog_post";

@Component({ components: { BFormInput, BFormGroup, BCol, BRow, PostCard } })
export default class PostsSearch extends Vue {
	blogPostModel = BlogPostAppModel;

	postResults: any[] = [];

	searchTerm = "";

	debouncedSearch: any;

	isDebouncing = false;

	created() {
		this.debouncedSearch = debounce(this.searchPosts, 400);
	}

	@Watch("searchTerm")
	async searchTermUpdated() {
		this.isDebouncing = true;
		this.debouncedSearch();
	}

	async searchPosts() {
		if (!this.searchTerm) {
			this.postResults = [];
			return;
		}

		this.$store.dispatch("app/showLoading");

		try {
			const posts = (await this.blogPostModel.search({
				filter: {
					field: "title",
					operator: "$cont",
					value: this.searchTerm,
				},
				join: { field: "mainCategory" },
			})) as BlogPost[];

			this.postResults = posts
				.map(post => inflateEntity(post) as BlogPost)
				.map(blogPost => ({
					...blogPost,
					category: blogPost.mainCategory.name,
					eventDate: blogPost.eventDate ? format(parseISO(String(blogPost.eventDate)), "dd/MM") : "",
				}));
			console.log(this.postResults);
		} catch (error) {
			console.error(error);
		}

		this.$store.dispatch("app/hideLoading");
		this.isDebouncing = false;
	}
}
