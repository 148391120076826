import { CrudModel } from "./_crud";
import blogPostCategoryAppModel from "./blog_post_category.app.model";
import mediaAppModel from "./media.app.model";
import blogPostSubcategoryAppModel from "./blog_post_subcategory.app.model";

export class BlogPostAppModel extends CrudModel {
	constructor() {
		super(
			"app/blog_post",
			[],
			[
				{ fieldKey: "mainCategory", model: blogPostCategoryAppModel },
				{ fieldKey: "subcategory", model: blogPostSubcategoryAppModel },
				{ fieldKey: "photos", model: mediaAppModel },
			],
		);
	}
}

export default new BlogPostAppModel();
